//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'ElLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    classCss: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['i18n']),
  },
  methods: {
    onClick() {
      window.location.href = this.href
    },
  },
}
